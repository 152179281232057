@use "../../global/constants";

.sidebar-block {
  position: fixed;
  top: 50%;
  left: 10%;
  transform: translate(-50%, -50%);
}

.nav {
  display: flex;
  flex-direction: column;
  text-align: left;
  gap: .5rem;

  a::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 0.1em;
    background-color: constants.$color-secondary;
    opacity: 0;
    transition: opacity 300ms, transform 300ms;
  }

  a:hover::after,
  a:focus::after {
    opacity: 1;
    transform: translate3d(0, 0.2em, 0);
  }
}

.links {
  margin-top: 1em;
  display: flex;
  flex-direction: column;
  text-align: center;
  gap: .5rem;

  i:hover {
    cursor: grab;
    color: constants.$color-secondary;
  }
}