@use "../../global/constants";

.project-block {
  margin-bottom: 7em;
}

.project-list {
  display: flex;
  flex-direction: column;
  gap: 1em;
}

.project-card {
  background-color: constants.$color-light-primary;
  padding: 1em;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  gap: 2em;

  @media screen and (max-width: 768px) {
    flex-direction: column;
  }

  h4 {
    margin: 0;
  }

  p {
    font-size: 1rem;
  }

  .icon {
    color: constants.$color-secondary;
  }

  .bitbucket-link {
    color: constants.$color-text;
    cursor: grab;
    margin-left: 1rem;
  }

  .stack {
    color: constants.$color-secondary;
  }

  .project-name {
    display: flex;
    flex-direction: row;
  }

  .project-icon {
    width: 30%;

    @media screen and (max-width: 768px) {
      align-self: center;
    }
  }

  .project-description {
    display: flex;
    flex-direction: column;
    width: 70%;

    @media screen and (max-width: 768px) {
      align-self: center;
      width: 90%;
    }
  }

}