@use "../../global/constants";


.expertise-list {
  display: grid;
  grid-auto-flow: row;
  grid-template-columns: repeat(2, 1fr);
  row-gap: 1em;
  border: 1px solid constants.$color-secondary;
  border-radius: 3%;
  padding: 1em;
  width: 70%;

  @media screen and (max-width: 768px) {
    width: 90%;
    margin: 0 auto;
  }

  .bar {
    @media screen and (max-width: 768px) {
      height: 5px;
      width: 100%;
      background-color: #DC052D;
      display: block;
      border-radius: 5px;
      transition: 0.3s ease;
    }
  }

  div {
    text-align: center;
  }

  i:hover {
    color: constants.$color-secondary;
  }
  p {
    margin: 0;
    font-size: 1.2rem;
  }
}

.expertise-block {
  margin-bottom: 7em;
}