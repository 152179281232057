@use "../../global/constants";

.about-block {
  margin-bottom: 7em;

  span {
    color: constants.$color-secondary;
  }

  p {
    margin-bottom: 0;
  }
}