@use "../../global/constants";

.experience-block {
  display: flex;
  flex-direction: row;
  gap: 2.5em;
  margin-bottom: 7em;

  @media screen and (max-width: 768px) {
    flex-direction: column;
    gap: 1em;
  }
}

.companies-buttons {
  display: flex;
  flex-direction: column;

  @media screen and (max-width: 768px) {
    display: flex;
    flex-direction: row;
    overflow-x: auto;
  }

  a {
    border-left: 1px solid constants.$color-text;
    cursor: grab;
    padding: 1em;
    font-size: 1rem;

    @media screen and (max-width: 768px) {
      border-left: none;
      border-bottom: 1px solid constants.$color-text;

      display: flex;
      -webkit-box-pack: center;
      justify-content: center;
      -webkit-box-align: center;
      align-items: center;
      min-width: 120px;
      padding: 0 20px;
      text-align: center;
    }
  }

  a:hover {
    border-left: 1px solid constants.$color-secondary;
    background-color: constants.$color-light-primary;

    @media screen and (max-width: 768px) {
      border-left: none;
      border-bottom: 1px solid constants.$color-secondary;
      background-color: constants.$color-light-primary;
    }
  }

  .button-clicked {
    border-left: 1px solid constants.$color-secondary;
    background-color: constants.$color-light-primary;
    color: constants.$color-secondary;

    @media screen and (max-width: 768px) {
      border-left: none;
      border-bottom: 1px solid constants.$color-secondary;
      background-color: constants.$color-light-primary;
    }

  }
}

//.fade {
//  animation: fade-in-keyframes 1.5s;
//}
//
//@keyframes fade-in-keyframes {
//  from {opacity: 0}
//  to {opacity: 1}
//}

.details {
  width: 65%;

  @media screen and (max-width: 768px) {
    width: 100%;
  }

  h3 {
    margin-top: 0;
    margin-bottom: 0;
  }

  h4 {
    margin-top: 1rem;
    margin-bottom: 0;
  }

  h6 {
    margin-top: 1rem;
    margin-bottom: 0;
    color: constants.$color-text;
    font-size: 1rem;
  }

  ul {
    margin-top: 1rem;
    list-style: none;
    height: 4.5em;
    overflow: auto;
    margin-bottom: 0;

    @media screen and (max-width: 768px) {
      height: 12em;
    }

    li {
      font-size: 1rem;
      color: constants.$color-text;
    }

    li::before {
      content: "\2022";
      color: constants.$color-secondary;
      font-weight: bold;
      display: inline-block;
      width: 1em;
      margin-left: -1em;
    }

  }

}

