@use "src/global/constants";

body {
  background-color: constants.$color-primary;
}

.App-sidebar {
  @media screen and (max-width: 768px) {
    display: none;
  }
}

.App-links {
  @media screen and (min-width: 769px) {
    display: none;
  }

  @media screen and (max-width: 768px) {
    display: flex;
    justify-content: center;
  }
}

.App-content {
  width: 55%;
  text-align: left;
  margin: 0 auto;

  @media screen and (max-width: 768px) {
    width: 80%;
  }
}

.App {
  text-align: center;
  min-height: 100vh;
  font-size: calc(10px + 2vmin);
  color: white;
  margin: 0;
}

.button-primary {
  border: 1px solid constants.$color-secondary;
  cursor: grab;
  text-align: center;
  padding: .5em;
  border-radius: 10px;
  color: constants.$color-text;
  background-color: transparent;
  height: 3em;
  font-size: 1.5rem;
}

.button-primary:hover {
  background-color: constants.$color-light-primary;
}

a {
  color: constants.$color-text;
  position: relative;
  text-decoration: none;
}

a:hover {
  color: constants.$color-secondary;
}

p {
  color: constants.$color-text;

}

input {
  height: 3em;
  border-radius: 7px;
  border:none;
  display:block;
}

label {
  font-size: 1rem;
  display:block;
  margin-bottom: .3em;
}

textarea {
  resize: none;
  height: 10em;
}

textarea::placeholder {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;;
}
