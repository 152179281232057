@use "../../global/constants";

.links {
  margin-top: 1em;
  display: flex;
  flex-direction: row;
  text-align: center;
  gap: .5rem;

  @media screen and (max-width: 768px) {
    font-size: 2em;
    gap: 1em;
  }

  i:hover {
    cursor: grab;
    color: constants.$color-secondary;
  }
}